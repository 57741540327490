<template>
    <div>
         <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                
                <a-form-item label="订单号:">
                    <a-input v-model="searchForm.out_trade_no" placeholder="订单号"></a-input>
                </a-form-item>
                <a-form-item label="交易单号:">
                    <a-input v-model="searchForm.transaction_id" placeholder="交易单号"></a-input>
                </a-form-item>
                <a-form-item label="平台" >
                    <a-select v-model="searchForm.payment_type" placeholder="请选择" style="width: 160px" >
                        <a-select-option value="0">
                            在聊天中收款
                        </a-select-option>
                        <a-select-option value="1">
                            收款码收款
                        </a-select-option>
                        <a-select-option value="2">
                            在直播间收款
                        </a-select-option>
                        <a-select-option value="3">
                            用产品图册收款
                        </a-select-option>
                        <a-select-option value="4">
                            小程序
                        </a-select-option>
                    </a-select>
                </a-form-item>
               <a-form-item label="订单状态" >
                    <a-select v-model="searchForm.status" placeholder="请选择" style="width: 160px" >
                        <a-select-option value="0">
                            未支付
                        </a-select-option>
                        <a-select-option value="1">
                            已支付
                        </a-select-option>
                        <a-select-option value="4">
                            已发货
                        </a-select-option>
                        <a-select-option value="9">
                            已取消
                        </a-select-option>
                    </a-select>
                </a-form-item>
                 <a-form-item label="交易状态" >
                    <a-select v-model="searchForm.trade_state" placeholder="请选择" style="width: 160px" >
                        <a-select-option value="1">
                            已完成
                        </a-select-option>
                        <a-select-option value="3">
                            已完成有退款
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="订单时间:">
                   <a-range-picker style="width:240px" @change="handleOrderDateChange" v-model="detailOrderDateRange"/>
                </a-form-item>
                <a-form-item 
                    label='交易时间'>
                    <a-range-picker style="width:240px" @change="handlePayDateChange" v-model="detailPayDateRange"/>
                </a-form-item>
                <a-form-item 
                    class="pct"
                    label='省市区'>
                      <v-distpicker @province="val=>searchForm.province = val.value" @city="val=>searchForm.city = val.value" @area="val=>searchForm.district = val.value"></v-distpicker>
                </a-form-item>
                
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleClickSearch">
                        搜索
                    </a-button>    
                </a-form-item>
                <a-form-item >
                    <a-button 
                            icon="reload"
                            @click="handleResetForm">
                        重置
                    </a-button>
                </a-form-item>
            </a-form>
        </a-card>
         <a-card>
            <a-table
                :scroll="{ x: 1300 }"
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <span slot="price" slot-scope="text">{{(text/100).toFixed(2)}}</span>
                <template slot="time" slot-scope="text">{{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}</template>
                <span slot="type" slot-scope="text">
                    <a-tag  color="blue"  v-if="text">{{formatType(text)}}</a-tag>
                </span>
                <span slot="status" slot-scope="text">
                    <a-tag  color="blue"  v-if="text">{{formatStatus(text)}}</a-tag>
                </span>
                <a-tag slot="state" :color="text===1?'green':'red'"  slot-scope="text">{{text===1?'已完成':'已完成有退款'}}</a-tag>
                <template slot="action" slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button type="link" @click="handleDetail(record)">详情</a-button>
                    </div>  
                </template>
            </a-table>
         </a-card>
         <a-modal v-model="visible" title="订单详情" width="600px" :footer="null">
              <div class="detail-view">
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">交易单号:</a-col>
                        <a-col class="value" :span="14">{{billObj.transaction_id}}</a-col>
                    </a-row>
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">订单号:</a-col>
                        <a-col class="value" :span="14">{{billObj.out_trade_no}}</a-col>
                    </a-row>
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">订单状态:</a-col>
                        <a-col class="value" :span="14">
                            {{formatStatus(billObj.payment_type)}}
                        </a-col>
                    </a-row>
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">交易状态:</a-col>
                        <a-col class="value" :span="14">{{billObj.trade_state===1?'已完成':'已完成有退款'}}</a-col>
                    </a-row>
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">交易时间:</a-col>
                        <a-col class="value" :span="14">
                           {{billObj.pay_time | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                        </a-col>
                    </a-row>
                    
                    <a-row class="detail-row" v-if="billObj.payee_userid">
                        <a-col class="label" :span="6">此单业务员:</a-col>
                        <a-col class="value" :span="14">
                            {{billObj.payee_userid}}
                        </a-col>
                    </a-row>
                    <a-row class="detail-row">
                        <a-col class="label" :span="6">收款金额(元):</a-col>
                        <a-col class="value" :span="14">
                            {{(billObj.total_fee/100).toFixed(2)}}
                        </a-col>
                    </a-row>
                     <a-row class="detail-row">
                        <a-col class="label" :span="6">收款方式:</a-col>
                        <a-col class="value" :span="14">
                            {{formatType(billObj.payment_type)}}
                        </a-col>
                    </a-row>
                     <a-row class="detail-row">
                        <a-col class="label" :span="6">收款备注:</a-col>
                        <a-col class="value" :span="14">
                            {{billObj.remark}}
                        </a-col>
                    </a-row>
                     <a-row class="detail-row" v-if="billObj.total_refund_fee">
                        <a-col class="label" :span="6">退款金额:</a-col>
                        <a-col class="value" :span="14">
                            {{billObj.total_refund_fee}}
                        </a-col>
                    </a-row>
              </div>
         </a-modal>
    </div>
</template>
<script>
const columns = [
    {
      title: "订单号",
      dataIndex: "out_trade_no",
      align: "center",
      width: 200,
      ellipsis: true,
    },
    {
      title: "平台",
      dataIndex: "payment_type",
      align: "center",
      ellipsis: true,
      width: 150,
      scopedSlots: { customRender: "type" },
    },
    {
      title: "收款金额(元)",
      dataIndex: "total_fee",
      align: "center",
      ellipsis: true,
      width: 120,
      scopedSlots: { customRender: "price" },
    },
    {
      title: "订单状态",
      dataIndex: "status",
      align: "center",
      ellipsis: true,
      width: 150,
      scopedSlots: { customRender: "status" },
    },
    {
      title: "交易状态",
      dataIndex: "trade_state",
      align: "center",
      ellipsis: true,
      width: 150,
      scopedSlots: { customRender: "state" },
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      align: "center",
      ellipsis: true,
      width: 200,
      scopedSlots: { customRender: "time" },
    },
    {
      title: "支付时间",
      dataIndex: "pay_time",
      align: "center",
      ellipsis: true,
      width: 200,
      scopedSlots: { customRender: "time" },
    },
    {
      title: "省",
      dataIndex: "province",
      align: "center",
      ellipsis: true,
    },
    {
      title: "市",
      dataIndex: "city",
      align: "center",
      ellipsis: true,
    },
    {
      title: "区",
      dataIndex: "district",
      align: "center",
      ellipsis: true,
    },
    {
      title: "详细地址",
      dataIndex: "address",
      align: "center",
      ellipsis: true,
      width: 250,
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        fixed: 'right',
        scopedSlots: { customRender: "action" },
    }
]
import {
    mapState
  } from 'vuex'
import VDistpicker from 'v-distpicker'
export default {
    name:'order',
    components: { VDistpicker },
    data(){
        return{
            searchForm:{
                out_trade_no:'',
                transaction_id:'',
                payment_type:undefined,
                fromCTime:'',
                toCTime:'',
                fromPTime:'',
                toPTime:'',
                province:'',
                city:'',
                district:'',
                status:undefined,
                trade_state:undefined,
            },
            detailOrderDateRange:undefined,
            detailPayDateRange:undefined,
            visible:false,
            loading:false,
            columns,
            billObj:{},
            tableData:[],
            userList:[],
            pagination:{
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },

        }
    },
    computed: {
        ...mapState(['mqttInit'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleClickSearch()
                    this.getUserList()
                }
            },
            immediate:true
        }
    },
    methods:{
        getProvince(val){
            console.log(val)
        },
        handleResetForm(){
            this.searchForm = {
                out_trade_no:'',
                transaction_id:'',
                payment_type:undefined,
                fromCTime:'',
                toCTime:'',
                fromPTime:'',
                toPTime:'',
                province:'',
                city:'',
                district:'',
                status:undefined,
                trade_state:undefined,
            }
        },
        handlePayDateChange(date,dateString){
            if(dateString[0]){
                let begin_time = Math.round(new Date(dateString[0]).getTime()/1000).toString()
                let end_time = Math.round(new Date(dateString[1]).getTime()/1000).toString()
                this.searchForm.fromPTime = begin_time
                this.searchForm.toPTime = end_time
            }else{
                this.searchForm.fromPTime = undefined
                this.searchForm.toPTime = undefined
            }
        },
        handleOrderDateChange(date,dateString){
            if(dateString[0]){
                let begin_time = Math.round(new Date(dateString[0]).getTime()/1000).toString()
                let end_time = Math.round(new Date(dateString[1]).getTime()/1000).toString()
                this.searchForm.fromCTime = begin_time
                this.searchForm.toCTime = end_time
            }else{
                this.searchForm.fromCTime = undefined
                this.searchForm.toCTime = undefined
            }
        },
        getOrderList(){
            let payload = {
                msgId:'listBill',
                data: {
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination .current,
                },
            };
            // this.searchForm = {
            //     out_trade_no:'',
            //     transaction_id:'',
            //     payment_type:undefined,
            //     fromCTime:'',
            //     toCTime:'',
            //     fromPTime:'',
            //     toPTime:'',
            //     province:'',
            //     city:'',
            //     district:'',
            //     status:undefined,
            //     trade_state:undefined,
            // }
            if(this.searchForm.payment_type!==undefined){
                payload.data.payment_type= Number(this.searchForm.payment_type)
            }
            if(this.searchForm.status!==undefined){
                payload.data.status= Number(this.searchForm.status)
            }
            if(this.searchForm.out_trade_no){
                payload.data.out_trade_no= this.searchForm.out_trade_no
            }
            if(this.searchForm.trade_state!==undefined){
                payload.data.trade_state= Number(this.searchForm.trade_state)
            }
            if(this.searchForm.transaction_id){
                payload.data.transaction_id= this.searchForm.transaction_id
            }
            if(this.searchForm.province){
                payload.data.province= this.searchForm.province
            }
            if(this.searchForm.city){
                payload.data.city= this.searchForm.city
            }
            if(this.searchForm.district){
                payload.data.district= this.searchForm.district
            }
            if(this.searchForm.fromPTime){
                 payload.data.fromPTime = this.searchForm.fromPTime
                 payload.data.toPTime = this.searchForm.toPTime
            }
            if(this.searchForm.fromCTime){
                 payload.data.fromCTime = this.searchForm.fromCTime
                 payload.data.toCTime = this.searchForm.toCTime
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/order/listBill",
                payload,
                },
                (topic, data) => {
                    if(data.code===200){
                        this.tableData = data.data.res;
                        this.pagination.total = data.data.count
                    }
                
                },
                this
            );
        },
        handleDetail(row){
            this.billObj = row
            this.visible = true
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.getOrderList()

        },
        handleTableChange(pagination){
            this.pagination = pagination
             this.getOrderList()
        },
        formatType(type){
            switch (type){
                case 0:
                    return '在聊天中收款';
                case 1:
                    return '收款码收款';
                case 2:
                    return '在直播间收款'
                case 3:
                    return '用产品图册收款'
                case 4:
                    return '小程序'
            }
        },
        formatStatus(status){
             switch (status){
                case 0:
                    return '未支付';
                case 1:
                    return '已支付';
                case 4:
                    return '已发货'
                case 9:
                    return '已取消'
            }
        },
        handleSelectSearch(val){
            this.getUserList(val)
        },
        handleSelectChange(){
            this.getUserList()
        },
         //获取企微列表
        getUserList(name=''){
            let payload = {
                msgId:'wxuserlist',
                data: {
                pageSize:100,
                pageNum:1,
                name
                },
            };

            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/searchWxUserByPage",
                payload,
                },
                (topic, data) => {
                if(data.code===200){
                    this.userList = data.data.res;
                }
                
                },
                this
            );
        },
    }
}
</script>
<style lang="scss">
 .distpicker-address-wrapper select{
    font-size: 0.8rem !important;
    height: 35px !important;
}
</style>
<style lang="scss" scoped>
    .detail-row{
        height: 60px;
        .label{
            text-align: right;
            margin-right: 40px;
        }
    }
</style>